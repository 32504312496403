<template>
	<div>{{ context }}</div>
</template>
<script>
	export default {
		name: "back_top",
		data() {
			return {
				context:`1、背包内选择需要取回的饰品，点击取回按钮，即可开启取回流程，等待报价发送成功后，可登录Steam接受报价。

接受报价时，请保持加速器开启状态。


2、为什么我的饰品报价直接取消了？

因Steam服务器存在不稳定现象，可能造成饰品报价取消的情况，建议您等待几个小时后再尝试重新取回。
报价有时间限制，收到等待接受报价的站内信后请及时前往Steam进行接受哦！


3、为什么提示我饰品无法取回？

账号交易权限被限制，请打开steam-社区-市场查看限制原因，确定解除后再尝试取回。
PS：如问题仍然存在，请联系Steam客服解决。


4、提示Steam未公开

打开 Steam 个人信息，点击编辑个人资料，找到隐私设置，将库存选项调整为公开，并将下面选项框的 ✓ 取消。
5、提示饰品存在问题

可能是由于发货系统出现问题或商品库存不足造成，建议您再尝试几次取回，如仍有问题，请联系站内右侧栏客服解决！


6、取回了好久一直没有发送报价

在发货机器人发出饰品请求后，请尽快回应饰品报价，
若您未在12小时内接受饰品报价，您的饰品会自动取消，返回网站背包。
请不要频繁的拒绝饰品报价，这可能会导致饰品暂挂（饰品暂挂是Steam官方保护您Steam账户的机制。当您与他人交换的时候，从您账户发出去的物品，在被送达之前，将被Steam暂挂15天。双方为好友超过1年则是为期1天的暂挂。）
PS：注意：请不要多次拒绝或者不接受报价，可能会影响您的交易体验！
请理性消费哦！`,
			}
		},
		mounted() {
		},
		methods: {
			
		},
	}
</script>
<style scoped lang="scss">
	#back_top {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		font-size: 26px;
		background: #4854c9;
		position: fixed;
		z-index: 1000;
		bottom: 100px;
		right: 10px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
	}
</style>
