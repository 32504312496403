<template>
	<div>{{ context }}</div>
</template>
<script>
	export default {
		name: "back_top",
		data() {
			return {
				context:`2021年8月30日，国家新闻出版署下发 关于进一步尸格管理切实防止未成年人沉译网络游戏的通知》《以下简称《通知》)

WanSKin作为一家专业从事网络游双建拟数字产品取多的平台，围烧未成年人保护积极行动，积极落实国寄出台的各种防止未成年人而迷网络游戏的政策和规定对禁止未成年人参与游戏虚拟物品采取了严格管控措施，在网站公告中予以特别警示。并采取措施严禁任何未成年人使用WanSKin平台，包括:建立用户实名认证系统、积极应和配合政府的要求等。

WanSkins平台还将对已通过实名认证的成年账产的便用进行相关监督管理。在服务上不断优化用户体验。在安全上进一步完着对用户鹰拟物品的保护，努力推动立良好的虚拟生态。`,
			}
		},
		mounted() {
		},
		methods: {
			
		},
	}
</script>
<style scoped lang="scss">
	#back_top {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		font-size: 26px;
		background: #4854c9;
		position: fixed;
		z-index: 1000;
		bottom: 100px;
		right: 10px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
	}
</style>
