<template>
	<div>{{ context }}</div>
</template>
<script>
	export default {
		name: "back_top",
		data() {
			return {
				context:`1、本站禁止未成年参与、禁止成年人充值

2、充值可获得平台币，平台币属于虚拟道具，可用于参与网站游戏

3、充值购买的虚拟道具不支持提现、不支持退款
`,
			}
		},
		mounted() {
		},
		methods: {
			
		},
	}
</script>
<style scoped lang="scss">
	#back_top {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		font-size: 26px;
		background: #4854c9;
		position: fixed;
		z-index: 1000;
		bottom: 100px;
		right: 10px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
	}
</style>
