import { render, staticRenderFns } from "./fdb.vue?vue&type=template&id=a5e744d0&scoped=true&"
import script from "./fdb.vue?vue&type=script&lang=js&"
export * from "./fdb.vue?vue&type=script&lang=js&"
import style0 from "./fdb.vue?vue&type=style&index=0&id=a5e744d0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5e744d0",
  null
  
)

export default component.exports