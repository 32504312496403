<template>
	<div>{{ context }}</div>
</template>
<script>
	export default {
		name: "back_top",
		data() {
			return {
				context:`2021年10月29日，教育部网站发布《教育部办公厅等六部门关于进一步加强预防中小学生沉迷网络游戏管理工作的通知》。通知要求：
	所有网络游戏用户提交的实名注册信息，必须通过国家新闻出版署网络游戏防沉迷实名验证系统验证。
验证为未成年人的用户，必须纳入统一的网络游戏防沉迷管理。

WanSkins作为一家专业从事网络游戏虚拟数字产品服务的平台，围绕未成年人保护积极行动，积极落实国家出台的各种防止未成年人沉迷网络游戏的政策和规定，对禁止未成年人参与游戏虚拟物品采取了严格管控措施，在网站公告中予以特别警示，并采取严格措施禁止任何未成年人使用WanSkins平台，包括：建立和完善用户实名认证系统、积极响应和配合政府的要求等。

WanSkins平台还将对已通过实名认证的成年账户的使用进行相关监督管理。在服务上不断优化用户体验，在安全上进一步完善对用户虚拟物品的保护，努力推动建立良好的虚拟生态。`,
			}
		},
		mounted() {
		},
		methods: {
			
		},
	}
</script>
<style scoped lang="scss">
	#back_top {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		font-size: 26px;
		background: #4854c9;
		position: fixed;
		z-index: 1000;
		bottom: 100px;
		right: 10px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
	}
</style>
